// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDuzTac5jgDz2s8TzddEcneXZk-l2UMdsk",
  authDomain: "featured-ai.firebaseapp.com",
  projectId: "featured-ai",
  storageBucket: "featured-ai.appspot.com",
  messagingSenderId: "494856608931",
  appId: "1:494856608931:web:d74ee81c66b0199c363316",
  measurementId: "G-93G8REL5XR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);

export const addToWaitlist = async (email: string) => {
  try {
    await addDoc(collection(db, "waitlist"), {
      email,
      timestamp: new Date(),
    });
    return { success: true, message: "Added to waitlist successfully!" };
  } catch (error) {
    console.error("Error adding to waitlist: ", error);
    return {
      success: false,
      message: "Error joining waitlist. Please try again.",
    };
  }
};

export const submitFeedback = async (email: string, message: string) => {
  try {
    await addDoc(collection(db, "feedback"), {
      email,
      message,
      timestamp: new Date(),
    });
    return { success: true, message: "Feedback submitted successfully!" };
  } catch (error) {
    console.error("Error submitting feedback: ", error);
    return {
      success: false,
      message: "Error submitting feedback. Please try again.",
    };
  }
};

export { db, auth };
