import React, { useState, FormEvent } from "react";
import { addToWaitlist, submitFeedback } from "./firebaseHelper";

const FeaturedAILandingPage: React.FC = () => {
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [waitlistEmail, setWaitlistEmail] = useState("");
  const [submitStatus, setSubmitStatus] = useState("");

  const handleContactSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitStatus("Submitting...");
    const result = await submitFeedback(contactEmail, contactMessage);
    setSubmitStatus(result.message);
    if (result.success) {
      setContactEmail("");
      setContactMessage("");
    }
  };

  const handleWaitlistSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitStatus("Submitting...");
    const result = await addToWaitlist(waitlistEmail);
    setSubmitStatus(result.message);
    if (result.success) {
      setWaitlistEmail("");
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col items-center min-h-screen">
      <header className="text-center p-6 bg-blue-500 text-white w-full">
        <h1 className="text-4xl font-bold">Featured AI</h1>
        <p className="mt-2">
          At Featured AI, we're revolutionizing how you interact with GPTs,
          making it easier than ever to create, customize, and monetize AI
          solutions. 🤖✨
        </p>
        <p className="mt-2">
          We're building in public and inviting you to join us on this journey.
          From idea to execution, our platform will empower users and creators
          to leverage GPT technology effortlessly. Stay tuned for updates, sneak
          peeks, and behind-the-scenes content as we bring our vision to life
          with the help of AI. 🛠️📈
        </p>
      </header>
      <main className="flex flex-col items-center w-full flex-grow">
        <section id="contact" className="p-6 w-full max-w-lg">
          <h2 className="text-2xl font-semibold text-center">Contact Me</h2>
          <form
            id="contact-form"
            className="mt-4 flex flex-col"
            onSubmit={handleContactSubmit}
          >
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
              className="p-2 w-full border rounded-md"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
            <textarea
              name="message"
              placeholder="Your Message"
              required
              className="p-2 w-full border rounded-md mt-2"
              value={contactMessage}
              onChange={(e) => setContactMessage(e.target.value)}
            ></textarea>
            <button
              type="submit"
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Send
            </button>
          </form>
        </section>
        <section id="waitlist" className="p-6 w-full max-w-lg mt-6">
          <h2 className="text-2xl font-semibold text-center">
            Join the Waitlist
          </h2>
          <form
            id="waitlist-form"
            className="mt-4 flex flex-col"
            onSubmit={handleWaitlistSubmit}
          >
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
              className="p-2 w-full border rounded-md"
              value={waitlistEmail}
              onChange={(e) => setWaitlistEmail(e.target.value)}
            />
            <button
              type="submit"
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md"
            >
              Join
            </button>
          </form>
        </section>
        {submitStatus && (
          <p className="mt-4 text-center font-semibold">{submitStatus}</p>
        )}
      </main>
      <footer className="text-center p-4 bg-gray-700 text-white w-full">
        <p>
          Powered by
          <a href="https://featured-ai.com" className="underline ml-1">
            featured-ai.com
          </a>
        </p>
      </footer>
    </div>
  );
};

export default FeaturedAILandingPage;
